import './login.css';
import Logo from '../Logo';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { filiadoAPI } from "../../servicos/filiado";

function Login(){

    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');

    const Logar = (event) => {
        event.preventDefault();

        const dados = {
            'login': email,
            'senha': senha
        }          

        setExibeErro(false);
        setMensagemErro("");

        if (document.getElementById('aceite').checked === false) {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de aceitar os Termos de serviços e Políticas de privacidade.");
            return;
        } 

        if (email === '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar o email.");
            return;
        }

        if (senha === '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar a senha.");
            return;
        }

        filiadoAPI.post('/login',dados)
        .then(resposta => {
            sessionStorage.setItem('token', resposta.data.token);
            sessionStorage.setItem('key', resposta.data.public_key);
            CarregarDados();
        })
        .catch(erro => {
            if(erro?.response?.data?.message){
                setExibeErro(true);
                setMensagemErro(erro.response.data.message);
            }else{
                setExibeErro(true);
                setMensagemErro('Ops! Ocorreu um erro inesperado.');
            }        
        })            
    }    

    const CarregarDados = () => {
        const fi100_key_public = sessionStorage.getItem('key');
        filiadoAPI.get(`/${fi100_key_public}`)
        .then(resposta => {
            sessionStorage.setItem('Nome', resposta.data.fi100_dsc_filiado);
            Entrar();
        }).catch(erro => {
            if(erro?.response?.data?.message){
                setExibeErro(true);
                setMensagemErro(erro.response.data.message);
            }else{
                setExibeErro(true);
                setMensagemErro('Ops! Ocorreu um erro inesperado.');
            }        
        })            
    }        

    let navigate = useNavigate();
    const Entrar = () => {
        setEmail('');
        setSenha('');            
        let path = '/filiado/emplacar';
        navigate(path);
    }

    const [exibeErro, setExibeErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');

    return (
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-lg-8">  
                    <div class="container">
                        <div class="row text-center login align-middle">
                            <div class="col"></div>
                            <div id="login" class="col-12 col-lg-7 d-flex flex-column justify-content-center align-items-center">
                                <Logo/>
                                <h4>Seja bem vindo(a)!</h4>
                                <p>Acesso Restrito</p>

                                {exibeErro &&
                                    <div class="alert alert-danger alert-login" role="alert">
                                        <span class="material-symbols-outlined">error</span>
                                        {mensagemErro}
                                    </div>
                                }
                                
                                <form class="row g-3 text-start" onSubmit={Logar}>
                                    <div class="col-md-12">
                                        <label for="inputEmail4" class="form-label">Email</label>
                                        <input type="email" class="form-control" id="email" value={email} onChange={evento => setEmail(evento.target.value)} />
                                    </div>
                                    <div class="col-md-12">
                                        <label for="inputPassword4" class="form-label">Senha</label>
                                        <input type="password" class="form-control" id="senha" value={senha} onChange={evento => setSenha(evento.target.value)}/>
                                    </div>
                                    <div class="col-md-12">                                        
                                        <input type="checkbox" id="aceite" value="1" />&nbsp;
                                        <label for="inputPassword4" class="form-label">                                            
                                            <a href='\documentos\PoliticaUsoPrivacidadeConexaoDetranPRSindilocPR.pdf' target='_blank'>
                                                Aceito e concordo com os Termos de serviço e Políticas de privacidade.
                                            </a>                                           
                                        </label>
                                    </div>
                                    <div class="col-12 text-center">
                                        <button type="submit" class="btn btn-primary-blue btn-primary">Entrar</button>
                                    </div>
                                </form>
                            </div>
                            <div class="col"></div>
                        </div>
                    </div>
                </div>
                <div id="fundo-login" class="col-12 col-lg-4"></div>
            </div>
        </div>
    )
}

export default Login;

